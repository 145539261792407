import * as yup from 'yup'
import type { LOCATION_SEARCH_TYPE } from '@/types'
import type { ChildrenAges } from '@pcln/traveler-selection'
import { STAY_DRIVE, FLIGHT } from './TripTypes'
import type { PackagesFormStateType } from '../types'

const LOCATION_REQUIRED = 'Please enter a city or airport'
const INVALID_DATE = 'Please select valid dates'
const DISALLOW_INFANT_INTL_FLIGHT =
  'Sorry, we do not offer lap infant seating on international flights'
const INFANT_CAPACITY_LIMIT = 'Only one infant per adult is allowed'
const CHILD_AGE_REQUIRED =
  "Please enter children's age to get the best price for your group"

const intlLocation = (locationObject: LOCATION_SEARCH_TYPE | null) =>
  locationObject &&
  typeof locationObject.countryCode === 'string' &&
  locationObject.countryCode.toUpperCase() !== 'US'

const isInfantFound = (childrenAges: ChildrenAges) =>
  Object.values(childrenAges).some(age => Number(age) === 0)

const isIntLocationWithInfant = (
  locationObject: LOCATION_SEARCH_TYPE | null,
  childrenAges: ChildrenAges
) => intlLocation(locationObject) && isInfantFound(childrenAges)

export const isInfantOnInternationalTravel = (
  startLocation: LOCATION_SEARCH_TYPE | null,
  endLocation: LOCATION_SEARCH_TYPE | null,
  childrenAges: ChildrenAges
) => {
  if (
    isIntLocationWithInfant(startLocation, childrenAges) ||
    isIntLocationWithInfant(endLocation, childrenAges)
  ) {
    return DISALLOW_INFANT_INTL_FLIGHT
  }
  return ''
}

function handleIsInfantOnInternationalTravel(this: yup.TestContext) {
  const { startLocation, endLocation, travelers } = this
    .parent as PackagesFormStateType
  const { childrenAges } = travelers
  if (
    isIntLocationWithInfant(startLocation, childrenAges) ||
    isIntLocationWithInfant(endLocation, childrenAges)
  ) {
    return false
  }
  return true
}

function handleIsChildrenAgeProvided(this: yup.TestContext) {
  const { childrenAges, children } = this.parent

  if (children === 0) {
    return true
  }
  const validAgesCount = Object.values(childrenAges || {}).filter(
    age => /^\d+$/.test(String(age)) && Number(age) >= 0
  ).length

  if (validAgesCount !== children) {
    return this.createError({
      message: CHILD_AGE_REQUIRED,
      path: this.path
    })
  }
  return true
}

export const isInfantLimitReachedPackages = (
  adults: number,
  childrenAges: ChildrenAges,
  tripType: string
) => {
  const lapInfants = Object.values(childrenAges).filter(age => {
    const numAge = parseInt(age, 10)
    return !Number.isNaN(numAge) && numAge === 0
  })
  const infantCount = lapInfants.length
  if (tripType?.includes(FLIGHT) && infantCount > adults) {
    return INFANT_CAPACITY_LIMIT
  }
  return ''
}

function handleIsInfantLimitReachedPackage(this: yup.TestContext) {
  const { tripType, travelers } = this.parent
  const { adults, childrenAges } = travelers
  if (isInfantLimitReachedPackages(adults, childrenAges, tripType)) {
    return false
  }
  return true
}

export const searchFormSchema = yup.object().shape({
  tripType: yup.string(),
  partialStay: yup.bool(),
  startLocation: yup
    .object()
    .nullable()
    .when('tripType', {
      is: STAY_DRIVE,
      then: schema => schema.nullable(),
      otherwise: schema => schema.nullable().required(LOCATION_REQUIRED)
    }),
  endLocation: yup.object().nullable().required(LOCATION_REQUIRED),
  rcLocation: yup
    .object()
    .nullable()
    .when('pickupCarSomewhere', {
      is: true,
      then: schema => schema.nullable().required(LOCATION_REQUIRED),
      otherwise: schema => schema.nullable()
    }),
  startDate: yup.string().required(INVALID_DATE),
  endDate: yup.string().required(INVALID_DATE),
  hotelStartDate: yup.string().when('partialStay', {
    is: true,
    then: schema => schema.required(INVALID_DATE)
  }),
  hotelEndDate: yup.string().when('partialStay', {
    is: true,
    then: schema => schema.required(INVALID_DATE)
  }),
  rcPickupDate: yup.string().when('pickupCarSomewhere', {
    is: true,
    then: schema => schema.nullable().required(INVALID_DATE)
  }),
  rcDropoffDate: yup.string().when('pickupCarSomewhere', {
    is: true,
    then: schema => schema.nullable().required(INVALID_DATE)
  }),
  travelers: yup.object().shape({
    children: yup.number().test({
      name: 'children',
      test() {
        return handleIsChildrenAgeProvided.call(this)
      }
    })
  }),
  isInfantOnInternationalTravel: yup
    .string()
    .test(
      'isInfantOnInternationalTravel',
      DISALLOW_INFANT_INTL_FLIGHT,
      handleIsInfantOnInternationalTravel
    ),
  isInfantLimitReachedPackages: yup
    .string()
    .test(
      'isInfantLimitReachedPackages',
      INFANT_CAPACITY_LIMIT,
      handleIsInfantLimitReachedPackage
    )
})
