import analytics from '@/shared-utils/analytics'
import { type GA4PageNameType } from '@/ga4types'
import type { PackagesFormStateType } from './types'

export default function firePackagesFormGA4Event(
  initialState: PackagesFormStateType,
  submittedState: PackagesFormStateType,
  pageName: GA4PageNameType
) {
  analytics.fireGA4Event({
    event: 'search',
    attributes: {
      cabin_class_change:
        initialState.includeBasicEconomy !== submittedState.includeBasicEconomy
          ? 'y'
          : 'n',
      date_change:
        initialState.startDate !== submittedState.startDate ||
        initialState.endDate !== submittedState.endDate ||
        initialState.hotelStartDate !== submittedState.hotelStartDate ||
        initialState.hotelEndDate !== submittedState.hotelEndDate ||
        initialState.rcPickupDate !== submittedState.rcPickupDate ||
        initialState.rcDropoffDate !== submittedState.rcDropoffDate
          ? 'y'
          : 'n',
      trip_start_city_change:
        initialState.startLocation !== submittedState.startLocation ? 'y' : 'n',
      trip_end_city_change:
        initialState.endLocation !== submittedState.endLocation ? 'y' : 'n',
      quantity_change:
        initialState.travelers.adults !== submittedState.travelers.adults ||
        initialState.travelers.children !== submittedState.travelers.children ||
        initialState.travelers.rooms !== submittedState.travelers.rooms
          ? 'y'
          : 'n',
      page_name: pageName,
      product_name: 'bundle'
    }
  })
}

export const trackPackageSearchLinkClick = (
  pageName: 'landing' | 'homepage'
) => {
  const ga4Event = {
    event: 'select_promotion',
    attributes: {
      type: 'package',
      page_name: pageName,
      product_name: 'bundle',
      link_name: 'advanced_search'
    }
  }
  analytics.fireGA4Event(ga4Event)
}
